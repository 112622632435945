.drawer{
    .divLogo{
        text-align: center;

        img{
            width: 180px;
        }
    }
      
    .suporteLink{
      text-decoration: none;
      color: #6F62F9;

      &:hover{
        opacity: 0.7;
        cursor: pointer;
      }

      &:active{
        opacity: 0.5;
        color: #6F62F9;
      }

    }
    
      
}